import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import Slider from "react-slick";
import TestimonyCard from "./testimonycard";

const Testimonials = () => {
  const companyName = `${process.env.REACT_APP_COMPANYNAME}`;
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true, // Enable auto-scroll
    autoplaySpeed: 4000, // Time in milliseconds between auto-scroll (3 seconds)

    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024, // Tablet and smaller
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // Mobile
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="max-w-7xl mx-auto px-4">
      <div className="text-center mt-12">
        <h1 className="text-4xl font-bold">Testimonials</h1>
        <p className="text-[#161C2D] text-sm mt-4">
          Discover how {companyName} has transformed the supply chain management
          for pharmacies just like yours. Our users appreciate the convenience,
          efficiency, and reliability that {companyName} brings to their daily
          operations.
        </p>
      </div>

      <div className="mt-8 space-x-6">
        <Slider {...settings}>
          <TestimonyCard />
          <TestimonyCard />
          <TestimonyCard />
          <TestimonyCard />
          <TestimonyCard />
        </Slider>
      </div>
    </div>
  );
};

export default Testimonials;
