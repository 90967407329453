import Axios from "axios";
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import signInSchema1 from "../../../helpers/validations/signinInputs/signUpValidation/signUp1";

import "../../cssforpath/thesigninpage.css";

const WsThesigninpage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userId = searchParams.get("id");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(signInSchema1),
  });

  Axios.defaults.withCredentials = true;
  const navigate = useNavigate();

  const onSubmit = (data) => {
    // Include the userId in the form data
    const updatedData = { ...data, userId };
    console.log("🚀 ~ onSubmit ~ updatedData:", updatedData)

    const apiUrl = `${process.env.REACT_APP_API_URL}/pharmacy/register`;

    Axios.post(apiUrl, updatedData)
    
      .then((res) => {
        console.log("Response:", res.data);
        if (res.data.Status === "Success") {
          const id = res.data.id;
          navigate("/pharmacy/phsignUpadress", { state: { data: id } });
        } else {
          alert("Unable to register");
        }
      })
      .catch((error) => {
        console.error("Error during registration:", error);
        alert("Registration failed. Please try again.");
      });
  };

  return (
    <div className="container">
      <h2>Sign In </h2>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <label>
            Select an Option:
            <select {...register("buyerOption")}>
              <option value="">-- Select Option --</option>
              <option value="Pharmacy">Pharmacy</option>
              <option value="Drug Store">Drug Store</option>
              <option value="Optic Hospital">Optic Hospital</option>
              <option value="Dental Hospital">Dental Hospital</option>
              <option value="General Hospital">General Hospital</option>
              <option value="Hospital">Hospital</option>
              <option value="Medium Hospital">Medium Hospital</option>
              <option value="Small Hospital">Small Hospital</option>
              <option value="Laboratory">Laboratory </option>
              <option value="Medical Plaza">Medical Plaza </option>
              <option value="Diagnostic Laboratory">
                Diagnostic Laboratory
              </option>
              <option value="Diagnostic Center">Diagnostic Center </option>
              <option value="Specialty Center">Specialty Center</option>
            </select>
          </label>
          <p>{errors.buyerOption?.message}</p>
        </div>
        <div className="formInput">
          <label>
            Username:
            <input type="text" {...register("username")} />
            <p>{errors.username?.message}</p>
          </label>
        </div>

        <div className="form-group">
          <label>
            Company Name:
            <input type="text" {...register("companyName")} />
            <p>{errors.companyName?.message}</p>
          </label>
        </div>

        <div className="form-group">
          <label>
            Phone Number:
            <input type="text" {...register("phoneNumber")} />
            <p>{errors.phoneNumber?.message}</p>
          </label>
        </div>

        <div className="form-group">
          <label>
            License Number:
            <input type="text" {...register("licenceNumber")} />
            <p>{errors.licenceNumber?.message}</p>
          </label>
        </div>

        <div className="form-group">
          <label>
            TIN Number:
            <input type="text" {...register("tinNumber")} />
            <p>{errors.tinNumber?.message}</p>
          </label>
        </div>

        <div className="form-group">
          <label>
            Password:
            <input type="password" {...register("password")} />
            <p>{errors.password?.message}</p>
          </label>
        </div>

        <div className="form-group">
          <label>
            Confirm Password:
            <input type="password" {...register("confirmPassword")} />
            <p>{errors.confirmPassword?.message}</p>
          </label>
        </div>

        <div className="form-group">
          <button type="submit" className="signup-btn">
            Sign Up
          </button>
        </div>

        <div className="terms">
          <p>YOU AGREE TO OUR TERMS AND POLICIES</p>
        </div>

        <div className="login-link">
          <Link to="/login">Log in to existing account</Link>
        </div>
      </form>
    </div>
  );
};

export default WsThesigninpage;
