/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./cssforpath/firstPage.css"; // Import your CSS file
import bluePillsImg from "../pics/bluePills.jpeg";
import bluePills2 from "../pics/loginPagepic.jpg";
import testimonialsImg from "../pics/image1.jpeg";
import test2 from "../helpers/logo/Untitled1.svg";
import FeatureCard from "./firstPageComponent/card";
import Testimonials from "./firstPageComponent/testimonypage";
import Footer from "./firstPageComponent/Footer";
// import Hero from "./firstPageComponent/hero";
import { FaBoxes } from "react-icons/fa";
import { FiShoppingCart } from "react-icons/fi";
import { MdTrackChanges } from "react-icons/md";
import { AiFillLock } from "react-icons/ai";
import { GiChecklist } from "react-icons/gi";
import Slider from "react-slick";
const FirstPage = () => {
  const [selectedValue, setSelectedValue] = useState("ethiopia"); // Default to Ethiopia
  const [isButtonDisabled, setIsButtonDisabled] = useState(false); // Default enabled for Ethiopia
  const [isOpen, setIsOpen] = useState(false);
  const companyName = `${process.env.REACT_APP_COMPANYNAME}`;
  const handleSelectChange = (event) => {
    const { value } = event.target;
    setSelectedValue(value);
    setIsButtonDisabled(value !== "ethiopia"); // Disable button if not Ethiopia
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true, // Enable auto-scroll
    autoplaySpeed: 4000, // Time in milliseconds between auto-scroll (3 seconds)

    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024, // Tablet and smaller
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // Mobile
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="Navbar">
        <span className="nav-logo">
          <img className="logo" src={test2} alt="" />
        </span>
        <div className={`nav-items ${isOpen ? "open" : ""}`}>
          <a href="/">Home</a>
          <a href="#about">About</a>
          <a href="#contact">Contact</a>
          <select value={selectedValue} onChange={handleSelectChange}>
            {/* <option value="">Country</option> */}
            <option value="ethiopia">ETHIOPIA</option>
            <option value="kenya">KENYA</option>
            <option value="sudan">SUDAN</option>
            <option value="eritrea">ERITREA</option>
          </select>
          <Link to="/login" className="nav-link">
            <button disabled={isButtonDisabled}>Login</button>
          </Link>
          <Link to="/signUp" className="nav-link">
            <button disabled={isButtonDisabled}>Sign Up</button>
          </Link>
        </div>
        <div
          className={`nav-toggle ${isOpen ? "open" : ""}`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className="bar"></div>
        </div>
      </div>
      <div className="">
        {/* Hero Section */}
        <header
          id="home"
          className="relative shadow-xl overflow-hidden"
          style={{
            backgroundImage: `url(${bluePills2})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          {/* Subtle Gradient Overlay */}
          <div className="absolute inset-0 bg-gradient-to-b from-blue-600 to-white opacity-50"></div>

          <div className="relative flex flex-col-reverse md:flex-row gap-5 px-5 py-[70px] md:py-[110px] items-center max-w-6xl mx-auto">
            {/* Animated Wavy Border Section */}
            <div className="relative w-full md:w-1/2 flex justify-center items-center">
              <div
                className="relative w-64 h-64 flex justify-center items-center animate-random-wobble-border"
                style={{
                  clipPath: "circle(50% at 50% 50%)", // Larger default circular shape
                }}
              >
                {/* Image Inside Animated Border */}
                <img
                  src={bluePillsImg}
                  alt="Hero Image"
                  className="absolute rounded-full inset-0 w-full h-full object-cover"
                  style={{
                    transform: "scale(1.5)", // Larger initial zoom
                    animation: "reveal-image 5s infinite ease-in-out", // Sync with border animation
                  }}
                />
              </div>
            </div>

            {/* Text Content */}
            <div className="flex flex-col items-center text-center space-y-4">
              <p className="text-3xl font-extrabold text-blue-900 uppercase">
                Welcome to {companyName}
              </p>
              <p className="text-xl font-semibold text-blue-800 pb-4">
                Connecting Pharmacies with Trusted Wholesale Suppliers
              </p>
              <p className="text-base text-gray-700 leading-relaxed">
                {companyName} is a revolutionary e-commerce platform designed to
                streamline the pharmaceutical supply chain. Our mission is to
                connect pharmacies with reliable wholesale suppliers, providing
                a seamless, efficient, and secure way to manage your
                pharmaceutical orders. Whether you're a small independent
                pharmacy or a large chain, {companyName} offers the tools you
                need to ensure you have the right products at the right time.
              </p>
            </div>
          </div>
        </header>

        {/* <Hero
          title="Welcome to GoDrugs"
          icon={FaPlayCircle}
          description="Your one-stop solution for all pharmacy and wholesale needs. Manage orders, track invoices, and much more."
        /> */}
        <div className="max-w-7xl px-5 md:px-0 mx-auto text-start ">
          <div className="w-full mt-[70px] flex justify-center">
            <p className="text-[38px] font-bold capitalize">
              Why Choose {companyName}
            </p>
          </div>
          <div className=" mt-8 space-x-6">
            <Slider {...settings}>
              <FeatureCard
                description={
                  "Access a comprehensive database of pharmaceutical products from trusted suppliers."
                }
                Icon={FaBoxes}
                title={"Extensive Product Range:"}
              />
              <FeatureCard
                description={
                  "Place orders with a few clicks, and enjoy flexible payment options."
                }
                Icon={FiShoppingCart}
                title={"Easy Ordering:"}
              />
              <FeatureCard
                description={
                  "Monitor your orders with real-time updates and ensure timely deliveries."
                }
                Icon={MdTrackChanges}
                title={"Real-Time Tracking:"}
              />
              <FeatureCard
                description={
                  "Our platform uses advanced security measures to protect your data and transactions."
                }
                Icon={AiFillLock}
                title={"Secure Transactions:"}
              />
              <FeatureCard
                description={
                  "Ensure your pharmacy meets all regulatory requirements with our built-in compliance tools."
                }
                Icon={GiChecklist}
                title={"Regulatory Compliance:"}
              />
            </Slider>
          </div>
        </div>
        {/* About Us Section */}
        <div id="about">
          <div className="w-full mt-[60px] flex justify-center ">
            <p className="text-[45px] -mb-4 italic font-bold">About Us</p>
          </div>

          <section
            id=""
            className="flex flex-col md:flex-row  items-center justify-between max-w-6xl mx-auto"
          >
            <div>
              <p className="max-w-[450px]">
                At {companyName}, we understand the complexities and challenges
                of managing pharmaceutical supplies. Our platform is built by
                professionals with extensive experience in the pharmaceutical
                industry, ensuring that every feature is tailored to meet your
                needs. From product search and comparison to order tracking and
                delivery updates, {companyName} simplifies every step of the
                process.
              </p>
            </div>
            <img
              src={testimonialsImg}
              alt="Testimonials"
              className="section-img"
            />
          </section>
        </div>
        {/* Features Section */}
        {/* <section id="features">
          <h2>Why Choose {companyName}?</h2>
          <ul style={{ listStyleType: "none", padding: 0 }}>
            <li>
              <p>
                <strong>Extensive Product Range:</strong> Access a comprehensive
                database of pharmaceutical products from trusted suppliers.
              </p>
            </li>
            <li>
              <p>
                <strong>Easy Ordering:</strong> Place orders with a few clicks,
                and enjoy flexible payment options.
              </p>
            </li>
            <li>
              <p>
                <strong>Real-Time Tracking:</strong> Monitor your orders with
                real-time updates and ensure timely deliveries.
              </p>
            </li>
            <li>
              <p>
                <strong>Secure Transactions:</strong> Our platform uses advanced
                security measures to protect your data and transactions.
              </p>
            </li>
            <li>
              <p>
                <strong>Regulatory Compliance:</strong> Ensure your pharmacy
                meets all regulatory requirements with our built-in compliance
                tools.
              </p>
            </li>
          </ul>
        </section> */}
        {/* Testimonials Section */}
        {/* <section id="testimonials">
            <div className="section-content">
            
              <div className="section-text">
                <h2>What Our Users Say</h2>
                <p>
                  Discover how {companyName} has transformed the supply chain
                  management for pharmacies just like yours. Our users appreciate
                  the convenience, efficiency, and reliability that {companyName}
                  brings to their daily operations.
                </p>
              </div>
            </div>
          </section> */}
        <div className="px-5 md:px-0">
          <Testimonials />
        </div>
        <div id="contact">
          <Footer />
        </div>
        {/* Call to Action Section */}
        {/* Footer */}
        {/* <footer>
          <p>
            <strong>Contact Information:</strong> Phone number: 0935968129,
            email address: Naol@jabezremedy.com, and physical address.
          </p>
          <p>
            <Link to="/privacyPolicy">Privacy Policy</Link> |
            <a href="#terms-of-service">Terms of Service</a> |
            <a href="#help-center">Help Center</a>
          </p>
          <p>© {companyName}. All rights reserved</p>
        </footer> */}
      </div>
    </>
  );
};

export default FirstPage;
