const Footer = () => {
  const companyName = `${process.env.REACT_APP_COMPANYNAME}`;
  return (
    <footer className="mt-[80px] w-full text-white py-[60px]">
      <div className=" mx-auto px-4">
        <div className="flex flex-col md:flex-row  text-gray-300 justify-between items-start md:items-center">
          {/* About Section */}
          <div className="mb-6 md:mb-0 text-[14px]">
            <h2 className="text-white font-semibold ">
              Join {companyName} Today
            </h2>
            <p className="mt-2  text-gray-300 text-[14.5px] max-w-[265px]">
              Ready to experience the future of pharmaceutical supply
              management? Sign up now and take the first step towards a more
              efficient and reliable way to manage your pharmacy's inventory.
            </p>
          </div>

          {/* Contact Section */}
          <div className="mb-6 md:mb-0 text-[14px] text-gray-300">
            <h2 className="text-white font-semibold">Contact Us</h2>
            <p className="mt-2">Adama, (Oromia)</p>
            <p className="mt-1">+251 (935) 968 129</p>
            <p className="mt-1">naol@jabezremedy.com</p>
          </div>

          {/* Newsletter Section */}
          <div>
            <h2 className="text-white  font-semibold">Newsletter</h2>
            <div className="flex md:flex-row flex-col space-y-2  md:-space-y-4 items-center gap-5 mt-2">
              <input
                type="email"
                placeholder="Enter your email"
                className="px-2  border border-gray-300 text-gray-800"
              />
              <button className="bg-[#00AEEF] text-white px-9 py-3  hover:bg-blue-500">
                Submit
              </button>
            </div>
            <p className=" max-w-[516px] text-sm text-gray-300   t-6">
              If you provide us with your email address, we will send you
              updates and news directly to your inbox. Stay informed about the
              latest developments and important announcements by signing up
              today!
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
