import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import MyTry from "../../helpers/loading/mytry/myTry";
import "./css/bincard.css";

const ProductBinCard = () => {
  const [productDetails, setProductDetails] = useState(null);
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const location = useLocation();
  const productId = location.state?.data;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const fetchUrl = `${process.env.REACT_APP_API_URL}/wholesale/binCard?id=${productId}`;
        const response = await axios.get(fetchUrl, { withCredentials: true });

        console.log("🚀 ~ fetchData ~ response:", response);

        // Access productId and history directly from response.data
        const fetchedId = response.data.productId;
        const fetchedHistory = response.data.history;

        console.log("🚀 ~ fetchData ~ fetchedHistory:", fetchedHistory);
        console.log("🚀 ~ fetchData ~ fetchedId:", fetchedId);
        console.log("🚀 ~ fetchData ~ productId:", productId);

        if (String(fetchedId) === String(productId)) {
          const productInfo = fetchedHistory.find(
            (item) => item.type === "onHand"
          );

          if (productInfo) {
            setProductDetails({
              drug_name: productInfo.drug_name,
              dosage: productInfo.dosage,
              dose: productInfo.dose,
              brand_name: productInfo.brand_name,
              batchNum: productInfo.batchNum,
              drug_expdate: productInfo.drug_expdate,
              beginning_balance: productInfo.biginning_balance,
              current_stock: productInfo.current_stock,
              date: new Date(productInfo.date).toLocaleDateString(),
            });
          } else {
            setError("No on-hand product details found");
          }

          setHistory(fetchedHistory);
        } else {
          setError("Product not found");
        }
      } catch (err) {
        setError("Failed to fetch data");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [productId]);

  if (loading) return <MyTry />;
  if (error) return <p>{error}</p>;

  return (
    <div className="bin-card-container">
      {/* Product Description Section */}
      <section className="product-description">
        <h2>Product Details</h2>
        <p>
          <strong>Drug Name:</strong> {productDetails.drug_name}
        </p>
        <p>
          <strong>Dosage:</strong> {productDetails.dosage}
        </p>
        <p>
          <strong>Dose:</strong> {productDetails.dose}
        </p>
        <p>
          <strong>Brand Name:</strong> {productDetails.brand_name}
        </p>
        <p>
          <strong>Batch Number:</strong> {productDetails.batchNum}
        </p>
        <p>
          <strong>Expiration Date:</strong> {productDetails.drug_expdate}
        </p>
        <p>
          <strong>Beginning Balance:</strong> {productDetails.beginning_balance}
        </p>
        <p>
          <strong>Current Stock Quantity:</strong>
          {productDetails.current_stock}
        </p>
      </section>

      {/* Bin Card Table */}
      <table className="bin-card-table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Received</th>
            <th>Issued</th>
            <th>Deducted</th>
            <th>Reason</th>
            <th>Balance</th>
          </tr>
        </thead>
        <tbody>
          {/* Beginning Balance Row */}
          <tr>
            <td>{productDetails.date}</td>
            <td>{productDetails.beginning_balance}</td>
            <td></td>
            <td></td>
            <td>Beginning Balance</td>
            <td>{productDetails.beginning_balance}</td>
          </tr>
          {/* Dynamic Rows for History */}
          {(() => {
            let balance = productDetails.beginning_balance; // Starting balance
            return history
              .filter((entry) => entry.type !== "onHand") // Skip "onHand" entries
              .map((entry, index) => {
                const received = entry.type === "addition" ? entry.amount : 0;
                const issued = entry.type === "sold" ? -entry.amount : 0;
                const deducted = entry.type === "deducted" ? -entry.amount : 0;

                balance += received + issued + deducted; // Update balance for this row

                return (
                  <tr key={index}>
                    <td>{new Date(entry.date).toLocaleDateString()}</td>
                    <td>{received || ""}</td>
                    <td>{issued || ""}</td>
                    <td>{deducted || ""}</td>
                    <td>
                      {entry.type === "sold"
                        ? `Sold to ${entry.company_name}`
                        : entry.reason || "N/A"}
                    </td>
                    <td>{balance}</td>
                  </tr>
                );
              });
          })()}
          {/* Final Row for Current Stock */}
          {(() => {
            let finalCalculatedBalance = productDetails.beginning_balance; // Start with the beginning balance
            history.forEach((entry) => {
              const received = entry.type === "addition" ? entry.amount : 0;
              const issued = entry.type === "sold" ? -entry.amount : 0;
              const deducted = entry.type === "deducted" ? -entry.amount : 0;
              finalCalculatedBalance += received + issued + deducted;
            });
            console.log(
              "🚀 ~ history.forEach ~ finalCalculatedBalance:",
              finalCalculatedBalance
            );
            const currentStockQuantity = productDetails.current_stock; // Example value
            console.log(
              "🚀 ~ ProductBinCard ~ currentStockQuantity:",
              currentStockQuantity
            );

            if (finalCalculatedBalance === currentStockQuantity) {
              return (
                <tr>
                  <td>{new Date().toLocaleDateString()}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>Current Quantity</td>
                  <td>{currentStockQuantity}</td>
                </tr>
              );
            } else {
              return (
                <tr>
                  <td>{new Date().toLocaleDateString()}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    There seems to be an error. Please check for pending orders.
                  </td>
                  <td>Error</td>
                </tr>
              );
            }
          })()}
        </tbody>
      </table>
    </div>
  );
};

export default ProductBinCard;
