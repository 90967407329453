import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import MyTry from "../../../helpers/loading/mytry/myTry";

const FavoriteButton = ({ contact, ownId }) => {
  const [favorite, setFavorite] = useState(false);
  const [loading, setLoading] = useState(true); // Initial loading state is true
  const [cancelTokenSource, setCancelTokenSource] = useState(null);

  useEffect(() => {
    // Retrieve favorited contact IDs from cookies
    const favoritedIdsStr = Cookies.get("favorites");
    console.log("🚀 ~ useEffect ~ favoritedIdsStr:", favoritedIdsStr);
    const decodedFavoritedIdsStr = decodeURIComponent(favoritedIdsStr || "");
    const favoritedIds = decodedFavoritedIdsStr
      ? decodedFavoritedIdsStr.split(",")
      : [];

    // Check if the current contact is favorited
    setFavorite(favoritedIds.includes(String(contact)));

    // Set loading to false once initial check is complete
    setLoading(false);
  }, [contact]);

  useEffect(() => {
    // Clean up cancel token source if component unmounts
    return () => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Component unmounted");
      }
    };
  }, [cancelTokenSource]);

  const handleClick = async (e) => {
    e.preventDefault();

    if (loading) {
      return; // If still loading, prevent further actions
    }

    const source = axios.CancelToken.source();
    setCancelTokenSource(source);
    setLoading(true); // Set loading to true when a new request is initiated
    const submitUrl = `${process.env.REACT_APP_API_URL}/pharmacy/favorite`;

    console.log("🚀 ~ handleClick ~ submitUrl:", submitUrl);
    try {
      const response = await axios.post(
        submitUrl,
        {
          wholesale_id: contact,
          pharmacy_id: ownId,
          favorited: !favorite,
        },
        {
          withCredentials: true,
        },
        {
          cancelToken: source.token,
        }
      );
      console.log("🚀 ~ handleClick ~ response:", response);

      if (response.status === 200) {
        // Update the favorited state based on the API response
        setFavorite(!favorite);
      } else {
        console.error("Failed to update favorite status");
      }
    } catch (error) {
      console.error("An error occurred while toggling favorite status:", error);
    } finally {
      setLoading(false); // Set loading to false after the request completes
    }
  };

  return (
    <div>
      <form method="post" onSubmit={handleClick}>
        <button
          type="submit"
          disabled={loading}
          value={favorite ? "false" : "true"}
        >
          {loading ? <MyTry /> : favorite ? "♥ Favorited" : "♡ Not Favorited"}
        </button>
      </form>
    </div>
  );
};

export default FavoriteButton;
