import React, { useState } from "react";
import Loginform from "./components/loginform";
import SignInPage from "../../theSignInPage";
// import Countrystatecity from '../theSignInPage'
function App2() {
  const adminUser = {
    email: `${process.env.REACT_APP_EMAIL}`,
    password: `${process.env.REACT_APP_PASSWORD}`,
  };

  const [user, setuser] = useState({ name: "", email: "" });
  const [error, seterror] = useState("");

  const Login = (details) => {
    console.log(details);
    console.log("🚀 ~ Login ~ adminUser.password:", adminUser.password);
    console.log("🚀 ~ Login ~ adminUser.email:", adminUser.email);

    if (
      details.email.trim().toLowerCase() ===
        adminUser.email.trim().toLowerCase() &&
      details.password.trim() === adminUser.password.trim()
    ) {
      console.log("logged Inn");
      setuser({
        name: details.name,
        email: details.email,
      });
    } else {
      console.log();
      seterror("details do not much");
    }
  };
  const Logout = () => {
    console.log("logout");
    setuser({ name: "", email: "" });
  };

  return (
    <div className="App">
      {user.email !== "" ? (
        <div className="welcome">
          <h2>
            welcome, <span>{user.name}</span>
          </h2>
          <SignInPage />
          <button onClick={Logout}>employe logout</button>
        </div>
      ) : (
        <Loginform Login={Login} error={error} />
      )}
    </div>
  );
}

export default App2;
