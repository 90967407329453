import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ResultsComponent from "../searchPages/ResultsComponent";
import "./css/displayFavorites.css";

const ListFavorites = () => {
  const location = useLocation();
  const { response, userId } = location.state || {};
  console.log("🚀 ~ ListFavorites ~ userId:", userId)
  console.log("🚀 ~ ListFavorites ~ response:", response);

  const [message, setMessage] = useState("");
  const [availableDetails, setAvailableDetails] = useState([]);
  const [notAvailableDetails, setNotAvailableDetails] = useState([]);

  useEffect(() => {
    if (!response) {
      // If no response is received, reset the sections and show a message
      setMessage("No response received.");
      setAvailableDetails([]);
      setNotAvailableDetails([]);
    } else if (response.error) {
      // Handle any errors in the response
      setMessage(response.error);
      setAvailableDetails([]);
      setNotAvailableDetails([]);
    } else {
      // Handle the response
      setMessage(response.message);

      const { newProducts = [], olderProducts = [] } = response.data || {};

      if (newProducts.length > 0) {
        // Set details for the "Available" section
        setAvailableDetails(
          newProducts.map((product) => ({
            id: product.id,
            companyName: product.companyName,
            userId,
          }))
        );
      } else {
        // If no new products, clear the available section
        setAvailableDetails([]);
      }

      if (olderProducts.length > 0) {
        // Set details for the "Not Available" section
        setNotAvailableDetails(
          olderProducts.map((product) => ({
            id: product.id,
            companyName: product.companyName,
            userId,
          }))
        );
      } else {
        // If no older products, clear the not available section
        setNotAvailableDetails([]);
      }
    }
  }, [response, userId]);

  console.log("🚀 ~ ListFavorites ~ availableDetails:", availableDetails);
  console.log("🚀 ~ ListFavorites ~ notAvailableDetails:", notAvailableDetails);

  return (
    <div className="container">
      <section className="section">
        {/* Conditional message display */}
        {message && <div className="message">{message}</div>}
        
        {availableDetails.length > 0 ? (
          <>
            <h1>These Wholesalers Have New Products</h1>
            {availableDetails.map((detail, index) => (
              <ResultsComponent
                key={index}
                wholeSale={detail}
                ownId={detail.userId}
              />
            ))}
          </>
        ) : (
          <h1>No New Products Available From Your Favorites</h1>
        )}
      </section>

      <span className="separator">|</span>

      <section className="section">
        {notAvailableDetails.length > 0 ? (
          <>
            <h1>These Wholesalers Have Older Products</h1>
            {notAvailableDetails.map((detail, index) => (
              <ResultsComponent
                key={index}
                wholeSale={detail}
                ownId={detail.userId}
              />
            ))}
          </>
        ) : (
          <h1>No Older Products Available From Your Favorites </h1>
        )}
      </section>

      <h1>Evaluate Your Suppliers:</h1>
      <p> Formal registration</p>
      <p> Reference checks with past clients and international agencies</p>
      <p> Test purchases in small quantities</p>
      <p> Informal local information gathering.</p>
    </div>
  );
};

export default ListFavorites;
