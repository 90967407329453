import React from "react";
// import { FaUser } from "react-icons/fa6";

const FeatureCard = ({ title, Icon, description }) => {
  return (
    <div className="border flex flex-col items-center justify-center gap-2 bg-white border-gray-300 rounded-lg p-6 text-center shadow-md max-w-sm mx-auto">
      {/* <img className="text-4xl mb-4" src={icon}></img>
       */}

      <div className="border rounded-full  size-[90px] flex justify-center items-center">
        <div>{Icon && <Icon className="text-[40px]" />}</div>
        {/* <FaUser className="text-[40px]" /> */}
      </div>
      <h2 className="text-2xl font-semibold mb-2">{title}</h2>
      <p className="text-gray-600">{description}</p>
    </div>
  );
};

export default FeatureCard;
